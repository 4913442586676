export default {
  methods: {
    authLogin() {
      this.$sitio.auth.open({
        parent: this.$root,
        action: "login",
      });
    },
    authSignup() {
      this.$sitio.auth.open({
        parent: this.$root,
        action: "signup",
      });
    },
    authForgot() {
      this.$sitio.auth.open({
        parent: this.$root,
        action: "forgot",
      });
    },
  },
};
