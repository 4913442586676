<script>
import auth from "@/mixins/auth";
import { mapGetters } from "vuex";
export default {
  name: "primaryNav",

  mixins: [auth],

  components: {
    accountPreview: () =>
      import(
        /* webpackChunkName: "account-preview" */ "@/components/account-preview"
      ),
  },

  data() {
    return {
      showAccountPreview: false,
    };
  },

  computed: {
    ...mapGetters("session", ["loggedIn", "accUserInfo"]),
  },
};
</script>

<template>
  <div id="primaryNav" class="">
    <div class="primaryNav--height"></div>
    <div class="primaryNav--height primaryNav__container">
      <div class="primaryNav__bg"></div>
      <div class="header_width primaryNav__content">
        <div class="bar__brand">
          <h1 class="logo">
            <router-link to="/">
              <img src="../assets/logo.png" alt="Sitio" />
            </router-link>
          </h1>
        </div>
        <div class="bar__nav">
          <!-- <div class="navmenu navmenu--start">
            <router-link class="navmenu__item" to="/propiedades"
              >Propiedades</router-link
            >
            <router-link class="navmenu__item" to="/publica">Publica</router-link>
          </div> -->
          <div class="navmenu navmenu--end">
            <!-- <router-link class="navmenu__item" to="/propiedades">Propiedades</router-link> -->
            <router-link
              class="navmenu__item"
              :to="{ name: 'acc-listings' }"
              v-if="!loggedIn"
              >Publica</router-link
            >
            <router-link class="navmenu__item" to="/account" v-if="loggedIn"
              >Panel de control</router-link
            >
            <!-- <router-link
              class="navmenu__item"
              to="/account/listings"
              v-if="loggedIn"
              >Mis anuncios</router-link
            > -->
            <div class="navmenu__item" v-if="!loggedIn">
              <div class="buttons">
                <b-button type="is-light" rounded @click="authLogin()">
                  <strong>Iniciar sesión</strong>
                </b-button>
                <b-button type="is-primary" rounded @click="authSignup()">
                  <strong>Registrarse</strong>
                </b-button>
              </div>
            </div>
            <div class="navmenu__item" v-else>
              <b-button
                type="is-light"
                rounded
                @click.prevent="$emit('toggle-app-account')"
                icon-left="user"
                >{{
                  accUserInfo ? accUserInfo.user_name_full : "Usuario"
                }}</b-button
              >
            </div>
            <div class="navmenu__item">
              <b-button
                type="is-light"
                rounded
                @click.prevent="$emit('toggle-app-settings')"
              >
                <b-icon icon="cog" />
                <!-- <i class="fas fa-cog"></i> -->
              </b-button>
            </div>
            <div
              v-if="loggedIn"
              id="sitioAccount"
              class="navmenu__item"
              :class="{ showAccountPreview }"
            >
              <button
                class="button is-rounded userBtn is-light"
                :class="{ active: showAccountPreview }"
                role="button"
                tabindex="0"
                @click.stop="showAccountPreview = !showAccountPreview"
              >
                <i class="fas fa-bars"></i>
                <div class="nickname">{{ accUserInfo.user_name_full }}</div>
                <div class="avatar">
                  <img
                    v-if="accUserInfo.user_avatar"
                    :src="accUserInfo.user_avatar.src"
                    :srcset="accUserInfo.user_avatar.srcset"
                  />
                  <img v-else src="@/assets/avatar-square.svg" />
                </div>
              </button>
              <accountPreview
                v-if="loggedIn && showAccountPreview"
                v-model="showAccountPreview"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.primaryNav--height {
  height: var(--sitio-desktop-nav-height);
}
.primaryNav__container {
  display: flex;
  // flex-direction: row;
  align-items: center;
  // background-color: #fff;
  z-index: 10;
  // box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.02);
  // box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.04);
  // box-shadow: 0px 3px 3px 0px rgba(46, 82, 107, 0.05);
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  top: 0;
  // border-bottom: 1px solid #ecf0f4;
  .primaryNav__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0 !important;
    background-color: #fff;
  }
}
.primaryNav__content {
  z-index: 1;
  display: flex;
  align-items: stretch;
  .bar__brand,
  .bar__nav {
    display: flex;
  }
  .bar__brand {
    .logo {
      display: flex;
      align-items: center;
      a {
        display: block;
      }
      img {
        display: block;
        max-height: 2.5rem;
      }
    }
  }
  .bar__nav {
    flex: 1 0 auto;
    align-items: stretch;
  }
  .navmenu {
    // flex: 1 1 auto;
    display: flex;
    align-items: stretch;
  }
  .navmenu__item {
    display: flex;
    align-items: center;
    // padding: 0.5rem 0.75rem;
    margin-right: 0.375rem;
  }
  .navmenu--start {
    justify-content: flex-start;
    margin-right: auto;
  }
  .navmenu--end {
    justify-content: flex-end;
    margin-left: auto;
  }
  a:not(.button) {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: $sitio1-dark;
    }
  }
  @media (max-width: 39.9375em) {
    display: none;
  }
}

#sitioAccount {
  display: flex;
  margin-right: -0.25rem;
  position: relative;
  .userBtn {
    display: inline-flex;
    // padding: 0;
    padding: 0.375rem;
    padding-left: 14px;
    align-items: center;
    height: auto;
    border: none;
    cursor: pointer;
    // background-color: transparent;
    outline: none;
    box-shadow: none;
    &:hover {
      background-color: rgba(60, 64, 67, 0.08);
    }
    &:active,
    &.active {
      background-color: rgba(60, 64, 67, 0.12);
    }
    .fa-bars {
      margin-right: 14px;
    }
    .avatar {
      display: inline-flex;
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      overflow: hidden;
      // background-color: #000;
      // background-color: #fff;
      img {
        display: block;
      }
    }
    .nickname {
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      display: none;
    }
  }
  .accountPreview {
    position: absolute;
    width: 20rem;
    right: 0;
    top: 3.75rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }
}
</style>
